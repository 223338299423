import KeenSlider from "keen-slider";
let rtlView = Shopify.locale == "ar" ? true : false;

let cateSliderEle = document.querySelector("#cate-slider");
if (cateSliderEle) {
  let cateSlider = new KeenSlider(cateSliderEle, {
    loop: false,
    rtl: rtlView,
    slides: {
      perView: "auto",
      spacing: 10,
      breakpoints: {
        "(min-width: 768px)": {
          disabled: true,
        },
      },
    },
  });
}
